body {
  margin: 0;
  /* font-family: "Source Sans Pro", sans-serif; */
  /* font-family: "Nunito Sans", sans-serif; */
  /* font-family: "Poppins", sans-serif; */
  font-family:  "Geologica", sans-serif;
 
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
li {
  text-decoration: none;
  margin: 0;
  padding: 0;
}
