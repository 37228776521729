#containerPag {
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.repo-title {
  margin-bottom: 1.5rem;
}

.listing {
  margin-bottom: 1rem;
}

.basic-single {
  width: 180px;
  cursor: pointer;
  /* border: 1px solid #ccc; */
}

.css-1s2u09g-control {
  border: none !important;
  font-size: small;
}
